import { APIFilter } from '@/utils/api'

export default {
  async selectServicio (Vue, idservicio) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idpuesto_servicio_servicio', idservicio)
    const resp = await Vue.$api.call('puestoServicioServicio.select', { filter: apiFilter })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
  async selectDetails(Vue, idservicio) {
    let apiCalls = []
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idpuesto_servicio_servicio', idservicio)
      .addGT('estado', 0)
    apiCalls.push({
      name: 'selectVigilanteCount',
      method: 'vigilante.select',
      params: { filter: apiFilter, wrapper: 'count' }
    })
    apiCalls.push({
      name: 'selectTurnoServicioCount',
      method: 'tturnoPuestoServicio.select',
      params: { filter: apiFilter, wrapper: 'count' }
    })
    return await Vue.$api.batchCall(apiCalls)
  },
}
